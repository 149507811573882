import React from "react";
import GetSVG from "../svg";

const SubPoints = [
  {
    iconName: "shield",
    name: "Prevent illegal downloads with Google & Apple DRM Encryption, Dynamic Watermark & User security analytics.",
  },
  {
    iconName: "videoplayer",
    name: "Our differentiator is easiest & quickest DRM + Video Security integrations in industry, we live by that.",
  },
  {
    iconName: "developcode",
    name: "Developer Integrations with APIs, SDKs and plugins for Web, Native, Flutter, React, Wordpress & Moodle. Highly scalable solution used by platforms with 10 Million Subscribers.",
  },
  {
    iconName: "padlock",
    name: "100% Download Prevention on all devices/browsers. 100% Screen Capture Block in Mobile Apps & iOS Browser. 70% Screen Capture Block in Chrome/Firefox; Rest 30% cases, no one else in the world can block.",
  },
];
function MainContent() {
  return (
    <div className="subcontent flxcenter">
      <ul>
        {SubPoints.map((sub) => (
          <li key={sub}>
            <span className="iconwrap">
              <GetSVG svgName={sub.iconName} />
            </span>
            {sub.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MainContent;
