import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const bgicon = [
  {
    id: "videolive",
    image: () => (
      <StaticImage
        className="livestream icon-set"
        src="https://www.vdocipher.com/blog/wp-content/uploads/2023/11/live-icon.png"
      />
    ),
  },
  {
    id: "analytics",
    image: () => (
      <StaticImage
        className="video1 icon-set"
        src="https://www.vdocipher.com/blog/wp-content/uploads/2023/11/analytics-icon.png"
      />
    ),
  },
  {
    id: "videosecurity",
    image: () => (
      <StaticImage
        className="player icon-set"
        src="https://www.vdocipher.com/blog/wp-content/uploads/2023/11/video-icon.png"
      />
    ),
  },
  {
    id: "videodrm",
    image: () => (
      <StaticImage
        className="player2 icon-set"
        src="https://www.vdocipher.com/blog/wp-content/uploads/2023/11/video1-icon.png"
      />
    ),
  },
];
function BackgroundIcons() {
  return (
    <div className="banner-main-animate mb-hidden">
      <div className="banner-main-animate mb-hidden">
        <ul className="animate-items">
          {bgicon.map((icons) => {
            return <li key={icons.id}>{icons.image()}</li>;
          })}
        </ul>
      </div>
    </div>
  );
}

export default BackgroundIcons;
