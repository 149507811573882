import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export default function CustomerReview() {
  return (
    <>
      <div className="customer-review-section bg-whitish-blue">
        <div className="max-width-div">
          <div className="row">
            <div className="header-text-container">
              <h2 style={{ marginTop: "30px" }}>
                Hear About Us From Social Media
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col l6 m6 s12">
              <div className="testimonial-image-wrapper">
                <StaticImage
                  alt="vdocipher-customer-review"
                  src="../../static/page-assets/linkedIn-review.jpg"
                />
              </div>
            </div>
            <div className="col l6 m6 s12">
              <div className="testimonial-image-wrapper">
                <StaticImage
                  alt="vdocipher-customer-review"
                  src="../../static/page-assets/other-review.jpg/"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
