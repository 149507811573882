import React from "react";
import Feature from "./feature";

// Reason to signup section

const featureListData = [
  {
    id: "drmEncryption",
    svgName: "drmIcon",
    link: "/blog/2020/09/encrypted-video-streaming-vdocipher-technology-details/",
    linkText: "Technology Details",
    secondLinkText: "How VdoCipher is more secure than others?",
    secondLink: "/page/securevideo",
    cardHeading: "Video DRM Encryption",
    cardDesctiption:
      "No downloader, plugin or hack can download the secure videos streamed through VdoCipher. Hollywood certified DRM to secure videos from piracy.",
  },
  {
    id: "dynamicWatermark",
    svgName: "dynamicWatermarkIcon",
    link: "/blog/2014/12/add-text-to-videos-with-watermark/",
    linkText: "Dynamic Watermarking Demo",
    cardHeading: "Dynamic Watermarking",
    cardDesctiption:
      "Total Screen Capture Block in Mobile Apps. Discourage screen capture in websites with user based watermark like IP address, user id, email id, phone no. etc.",
  },
  {
    id: "smartHtml",
    svgName: "smartHtml",
    link: "/page/custom-video-player",
    linkText: "All Custom Video Player Features",
    cardHeading: "Smart HTML5 Player",
    cardDesctiption:
      "Stream your videos with our customized smart video player with subtitles, multiple qualities & speed change options, compatible with all devices.",
  },
  {
    id: "pluginsIcon",
    svgName: "pluginsIcon",
    link: "/blog/upload-import-embed-overview-tutorial/",
    linkText: "Upload & Embed Tutorial",
    cardHeading: "Upload & Embed on Your Site Or App",
    cardDesctiption:
      "Embed player with subtitles, multiple qualities & speed change options. Option for the viewer to download securely in mobile app and play offline.",
  },
  {
    id: "aws",
    svgName: "aws",
    link: "/blog/2016/07/aws-cdn-infrastructure-details-vdocipher-video-streaming/",
    linkText: "CDN Infrastructure Details",
    cardHeading: "Amazon AWS Server + CDN",
    cardDesctiption:
      "Amazon AWS for Servers + CDN across 6 continents. Transcoding, Analytics, APIs, Dashboard, Adaptive Player included. Manage thousands of video easily.",
  },
  {
    id: "videoAnalytics",
    svgName: "webplugin",
    link: "page/api",
    linkText: "VdoCipher APIs & SDKs",
    cardHeading: "Effortless Developer Integrations",
    cardDesctiption:
      "Integrate effortlessly with minimal effort using our robust APIs , SDKs and plugins, specifically designed to simplify developers’ workflow.  Seamlessly integrate with web, native, flutter, react, wordpress and moodle.",
  },
];
const Reasons = () => (
  <div className="features-v2">
    <div className="max-width-div">
      <div className="header-text-container">
        <h2>Key Reasons to Sign Up</h2>
      </div>
      <div>
        <div
          className="row"
          style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch" }}
        >
          {featureListData.map((featureData) => (
            <div className="col l4 m6 s12" key={featureData.id}>
              <Feature
                svgName={featureData.svgName}
                cardHeading={featureData.cardHeading}
                cardDesctiption={featureData.cardDesctiption}
                link={featureData.link}
                linkText={featureData.linkText}
                secondLink={featureData.secondLink}
                secondLinkText={featureData.secondLinkText}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Reasons;
